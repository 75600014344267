import { CapacitorSQLite, capSQLiteChanges, capSQLiteSet, SQLiteConnection, SQLiteDBConnection } from "@capacitor-community/sqlite";
import { Capacitor } from "@capacitor/core";
import { Build } from '@stencil/core';
import { Mutex, withTimeout } from 'async-mutex';
import squel from "squel";

export type TransactionQueryInterface = <T>(set: capSQLiteSet) => Promise<T[]>;
export type TransactionExecuteInterface = (set: capSQLiteSet) => Promise<capSQLiteChanges>;
export type TransactionExecuteSetInterface = (set: capSQLiteSet[], batchSize?: number) => Promise<capSQLiteChanges>;

export interface Transaction {
  execute: TransactionExecuteInterface
  executeSet: TransactionExecuteSetInterface;
  query: TransactionQueryInterface;
  commit: () => Promise<void>;
  rollback: () => Promise<void>;
}

export interface SQLSelect extends squel.Select { };
export interface SQLUpdate extends squel.Update { };
export interface SQLInsert extends squel.Update { };
export interface SQLDelete extends squel.Update { };

export type SQLQueryBuilder = (query: SQLSelect) => SQLSelect;

export interface SQLColumn {
  name: string;
  type: string;
  pk: number;
}

const SQL_DEBUG = false;

export default class SQLDatabase {

  // Whether we are on the web platform
  private isWeb: boolean;

  // sqlite connection
  private sqlite: SQLiteConnection = new SQLiteConnection(CapacitorSQLite);

  // database connection
  private db: SQLiteDBConnection;

  // mutex for locking access to the connection
  transactionLock = withTimeout(new Mutex(), 10000);

  private debug: (...params: any) => void;

  public static instance: SQLDatabase = new SQLDatabase();

  private constructor() {
    this.debug = SQL_DEBUG ? window.console.log.bind(window.console, '%cSQL > ', 'background: #222; color: #bada55; border-radius: 4px; padding: 2px;') : () => { };
    // sqlTest();
  }

  public async init(databaseName: string) {
    this.isWeb = Capacitor.getPlatform() === 'web';
    // create a SQLite Connection Wrapper
    if (this.isWeb && !Build.isTesting) {
      await customElements.whenDefined('jeep-sqlite');
      await this.sqlite.initWebStore();
    }
    this.debug('Connecting to', databaseName);

    await this.sqlite.checkConnectionsConsistency();
    await this.sqlite.closeAllConnections();

    this.db = await this.createConnection(databaseName);
    await this.db.open();

    // (window as any).lockTransaction = async (timeout: number) => {
    //   console.log('Getting Transaction');

    //   const transaction = await this.transaction();
    //   console.log('Got Transaction');
    //   await new Promise(resolve => setTimeout(resolve, timeout));
    //   transaction.rollback();
    //   console.log('Released Transaction');
    // };
  }

  public async dropAllTables() {
    const operations: capSQLiteSet[] = [];
    for (const tableName of await this.getAllTables()) {
      operations.push({
        statement: `DROP TABLE ${tableName}`,
        values: []
      });
    }
    return this.executeSet(operations);
  }

  public async createConnection(databaseName: string) {
    return this.sqlite.createConnection(databaseName, false, 'no-encryption', 1, false);
  }

  private async postDBWrite() {
    if (this.isWeb) {
      //need to close the db to save it in indexeddb
      this.debug('postDBWrite');
      await this.db.close();
      await this.db.open();
    }
  }

  public static select() {
    return squel.select();
  }

  public static update() {
    return squel.update({ autoQuoteFieldNames: true });
  }

  public static insert() {
    return squel.insert();
  }

  public static delete() {
    return squel.delete();
  }

  public static expr() {
    return squel.expr();
  }

  public async query<T>(query: string | squel.Select | squel.Update | squel.Delete, values?: any[]): Promise<Array<T>> {
    this.debug(query.toString());
    return this.db.query(query.toString(), values).then(result => result.values ?? []);
  }

  public async querySet<T>(set: capSQLiteSet): Promise<Array<T>> {
    this.debug(set.statement, set.values);
    return this.db.query(set.statement, set.values).then(result => result.values ?? []);
  }

  public async execute(query: string | squel.Delete | squel.Update | squel.Insert, transaction: boolean = false): Promise<void> {
    this.debug(query.toString());
    await this.db.execute(query.toString(), false);
    if (!transaction) {
      await this.postDBWrite();
    }
  }

  public async executeSet(set: capSQLiteSet[], transaction: boolean = false): Promise<void> {
    this.debug(set);
    await this.db.executeSet(set, false);
    if (!transaction) {
      await this.postDBWrite();
    }
  }

  private startTransaction() {
    this.debug('BEGIN TRANSACTION;');
    return this.db.execute('BEGIN TRANSACTION;', false);
  }

  private async endTransaction(commit: boolean = true) {
    const operation = commit ? 'COMMIT;' : 'ROLLBACK;'
    this.debug(operation);
    await this.db.execute(operation, false);
    await this.postDBWrite();
  }

  public async transaction(): Promise<Transaction> {
    const release = await this.transactionLock.acquire();
    await this.startTransaction();
    let resolved = false;

    const transaction: Transaction = {
      execute: async (set: capSQLiteSet) => {
        this.debug(set);
        return this.db.executeSet([set], false);
      },
      executeSet: async (set: capSQLiteSet[], batchSize?: number) => {
        if (typeof batchSize === 'undefined') {
          this.debug(set);
          return this.db.executeSet(set, false);
        }

        let result: capSQLiteChanges;
        for (let i = 0; i < set.length; i += batchSize) {
          const setSlice = set.slice(i, i + batchSize);
          this.debug(setSlice);
          result = await this.db.executeSet(setSlice, false);
        }
        return result;
      },
      query: async (set: capSQLiteSet) => {
        this.debug(set);
        return this.db.query(set.statement, set.values).then(r => r.values ?? []);
      },
      commit: async () => {
        if (!resolved) {
          resolved = true;
          await this.endTransaction();
          release();
        }
      },
      rollback: async () => {
        if (!resolved) {
          resolved = true;
          await this.endTransaction(false);
          release();
        }
      }
    };

    return transaction;
  }

  public queryAll<T = any>(query: squel.Select, batch: number = 100): SQLPaginatedQuery<T> {
    return new SQLPaginatedQuery(query, batch);
  }

  public async tableExists(table: string): Promise<boolean> {
    const query = `PRAGMA table_info(${table})`;
    return await this.query<any[]>(query).then(result => {
      return result.length > 0;
    });
  }

  public async tableColumns(table: string): Promise<SQLColumn[]> {
    const query = `PRAGMA table_info(${table})`;
    return await this.query<{ name: string; type: string; pk: number }>(query);
  }

  public async getAllTables(): Promise<string[]> {
    const query = `SELECT name FROM sqlite_schema WHERE type = 'table' AND name NOT LIKE 'sqlite_%';`;
    const result = await this.query<{ name: string }>(query);
    return result.map(r => r.name);
  }

  public async getDbUrl() {
    return this.db.getUrl().then(res => res.url);
  }
}

export class SQLPaginatedQuery<T> implements AsyncIterable<T[]>, AsyncIterator<T[]> {
  private done = false;
  private offset = 0;

  constructor(
    readonly query: SQLSelect,
    readonly batch = 100
  ) { }

  isDone() {
    return this.done;
  }

  [Symbol.asyncIterator](): AsyncIterator<T[]> {
    return this;
  }

  async next(): Promise<IteratorResult<T[]>> {

    if (this.done) {
      return {
        done: true,
        value: []
      };
    }

    const result = await SQLDatabase.instance.query<T>(this.query.clone().limit(this.batch).offset(this.offset));
    const rows = result ?? [];

    if (rows.length === 0) {
      this.done = true;
    } else {
      if (rows.length < this.batch) {
        this.done = true;
      } else {
        this.offset += this.batch;
      }
    }

    return {
      done: rows.length === 0,
      value: rows
    };
  }

  async toArray() {
    const result = [];
    for await (const i of this) {
      result.push(...i);
    }
    return result;
  }
}

// if (Build.isDev) {
//   (window as any).sql = {
//     query: async (str: string) => {
//       SQLDatabase.instance.query(str).then(result => console.table(result));
//     }
//   }
// }
